import React from 'react';
import { ArrowDown, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import DocLeeLogo from "./DocleeLogo";

const ScreenshotsPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
            <header className="bg-white shadow-md">
                <div className="container mx-auto px-6 py-4 flex justify-between items-center">
                    <DocLeeLogo />
                    <Link to="/" className="flex items-center text-blue-600 hover:text-blue-800">
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Zpět na hlavní stránku
                    </Link>
                </div>
            </header>

            <main className="container mx-auto px-6 py-12">
                <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Jak DOC LEE funguje</h2>

                <div className="flex flex-col items-center space-y-12">
                    <ScreenshotSection
                        imageSrc="agent.png"
                        altText="DOC LEE scanning interface"
                        description="1. Skenování dokumentů"
                        details="Naše intuitivní rozhraní umožňuje rychlé a snadné skenování všech typů lékařských dokumentů."
                        imageClassName="w-full sm:w-2/3 md:w-1/2 lg:w-2/5 mx-auto"
                    />

                    <ArrowDown className="w-8 h-8 text-blue-600" />

                    <ScreenshotSection
                        imageSrc="patients.png"
                        altText="Patient list in DOC LEE"
                        description="2. Automatické třídění pacientů"
                        details="DOC LEE automaticky organizuje naskenované dokumenty podle pacientů, což šetří čas a minimalizuje chyby."
                        imageClassName="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto"
                    />

                    <ArrowDown className="w-8 h-8 text-blue-600" />

                    <ScreenshotSection
                        imageSrc="patient-files.png"
                        altText="Processed document in DOC LEE"
                        description="3. Zpracování a analýza dokumentů"
                        details="Náš systém využívá pokročilé algoritmy pro extrakci klíčových informací z dokumentů, což umožňuje rychlou a přesnou analýzu."
                        imageClassName="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto"
                    />

                    <ArrowDown className="w-8 h-8 text-blue-600" />

                    <ScreenshotSection
                        imageSrc="patient-file.png"
                        altText="Categorized documents for a patient in DOC LEE"
                        description="4. Přehledné zobrazení výsledků"
                        details="Všechny zpracované informace jsou prezentovány v přehledném a snadno použitelném rozhraní, což umožňuje rychlé rozhodování a efektivní péči o pacienty."
                        imageClassName="w-full md:w-3/4 lg:w-2/3 mx-auto"
                    />
                </div>
            </main>

            <footer className="bg-gray-100 py-6 mt-12">
                <div className="container mx-auto px-6 text-center text-gray-600">
                    &copy; 2024 DOC LEE. Všechna práva vyhrazena.
                </div>
            </footer>
        </div>
    );
};

const ScreenshotSection = ({ imageSrc, altText, description, details, imageClassName }) => (
    <div className="w-full max-w-5xl">
        <div className={imageClassName}>
            <img src={imageSrc} alt={altText} className="w-full rounded-lg shadow-md mb-4" />
        </div>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{description}</h3>
        <p className="text-gray-600">{details}</p>
    </div>
);

export default ScreenshotsPage;