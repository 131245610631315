import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import HowItWorksPage from './components/HowItWorksPage';
import { Analytics } from "@vercel/analytics/react"

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />
                    <Route path="/jak-to-funguje" element={<HowItWorksPage />} />
                </Routes>
            </Router>
            <Analytics></Analytics>
        </>
    );
}

export default App;

